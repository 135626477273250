import React from 'react';

function AboutUs() {
  const domains = [
    {
      id: 1,
      title: "Operations Excellence",
      description: "Optimize your operational processes with data-driven insights. We help streamline workflows, reduce inefficiencies, and improve productivity across your organization.",
      icon: "operations-icon.svg"
    },
    {
      id: 2,
      title: "Supply Chain Analytics",
      description: "Transform your supply chain with advanced analytics. From demand forecasting to inventory optimization, we help you build a resilient and efficient supply chain.",
      icon: "supply-chain-icon.svg"
    },
    {
      id: 3,
      title: "Maintenance & Reliability",
      description: "Implement predictive maintenance strategies and improve asset reliability. Our solutions help reduce downtime and optimize maintenance schedules.",
      icon: "maintenance-icon.svg"
    },
    {
      id: 4,
      title: "Data Governance",
      description: "Establish robust data governance frameworks that ensure data quality, security, and compliance while maximizing the value of your data assets.",
      icon: "governance-icon.svg"
    },
    {
      id: 5,
      title: "Business Intelligence",
      description: "Transform raw data into actionable insights with our BI solutions. Create interactive dashboards and reports that drive better decision-making.",
      icon: "bi-icon.svg"
    },
    {
      id: 6,
      title: "Manufacturing Analytics",
      description: "Optimize production processes, quality control, and resource utilization with advanced manufacturing analytics solutions.",
      icon: "manufacturing-icon.svg"
    }
  ];

  return (
    <div className="page-container">
      <div className="max-w-6xl mx-auto">
        <div className="content-section">
          {/* Header Section */}
          <div className="text-center mb-12">
            <h1 className="heading-1">About Us</h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We specialize in transforming businesses through data-driven solutions. 
              Our expertise spans across multiple domains, helping organizations make 
              better decisions and achieve operational excellence.
            </p>
          </div>

          {/* Domain Expertise Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
            {domains.map(domain => (
              <div key={domain.id} className="card hover:shadow-lg transition-shadow">
                <div className="p-6">
                  <img
                    src={`/images/icons/${domain.icon}`}
                    alt={domain.title}
                    className="w-16 h-16 mb-4"
                  />
                  <h3 className="heading-3 mb-3">{domain.title}</h3>
                  <p className="text-gray-600">{domain.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Mission Statement */}
          <div className="bg-[var(--neutral-light)] rounded-lg p-8 text-center mb-12">
            <h2 className="heading-2 mb-4">Our Mission</h2>
            <p className="text-lg text-gray-600">
              To empower organizations with data-driven insights and solutions that 
              drive operational excellence and sustainable growth. We combine industry 
              expertise with cutting-edge technology to deliver measurable results.
            </p>
          </div>

          {/* Contact Section */}
          <div className="text-center">
            <h2 className="heading-2 mb-4">Ready to Transform Your Business?</h2>
            <p className="text-lg text-gray-600 mb-6">
              Let's discuss how we can help you achieve your business goals through 
              data-driven solutions.
            </p>
            <button className="btn-primary">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;