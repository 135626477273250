import React from 'react';
import { useParams, Link } from 'react-router-dom';

function BlogPost() {
  const { id } = useParams();

  // Sample blog posts data - in production, this would come from an API
  const blogPosts = {
    1: {
      title: "Understanding Business Intelligence in the age of AI",
      date: "March 15, 2024",
      author: "redMug.co",
      readTime: "5 min read",
      category: "Business Intelligence",
      image: "/images/blog/bi-dashboard.svg",
      content: `
        <p class="lead">
          Business Intelligence (BI) has evolved significantly in recent years, transforming how organizations make decisions and drive growth.
        </p>

        <h2>The Evolution of Business Intelligence</h2>
        <p>
          Modern BI tools have revolutionized data analysis, making it more accessible and actionable than ever before. 
          Organizations can now process vast amounts of data in real-time, gaining insights that were previously impossible to obtain.
        </p>

        <h2>Key Components of Modern BI</h2>
        <ul>
          <li>Real-time data processing and analysis</li>
          <li>Interactive dashboards and visualizations</li>
          <li>Predictive analytics capabilities</li>
          <li>Integration with multiple data sources</li>
        </ul>

        <blockquote>
          "The future of business intelligence lies in its ability to democratize data analysis, making it accessible to everyone in an organization."
        </blockquote>

        <h2>Implementation Strategies</h2>
        <p>
          Successfully implementing BI tools requires a strategic approach:
        </p>

        <ol>
          <li>Assess your organization's data needs</li>
          <li>Choose the right BI tools for your specific requirements</li>
          <li>Develop a data governance framework</li>
          <li>Train your team on using BI tools effectively</li>
        </ol>

        <div class="info-box">
          <h3>Pro Tip</h3>
          <p>
            Start with a small pilot project to test your BI implementation before rolling it out across the entire organization.
          </p>
        </div>
      `
    },
    2: {
      title: "Data Security Best Practices for Small Businesses",
      date: "March 12, 2024",
      author: "redMug.co",
      readTime: "7 min read",
      category: "Security",
      image: "/images/blog/security.svg",
      content: `
        <p class="lead">
          In today's digital landscape, data security is crucial for businesses of all sizes. Small businesses, in particular, need to be vigilant about protecting their sensitive information.
        </p>

        <h2>Essential Security Measures</h2>
        <p>
          Implementing robust security measures doesn't have to be complicated or expensive. Here are key practices every small business should consider.
        </p>

        <ul>
          <li>Regular security audits and assessments</li>
          <li>Employee training on security best practices</li>
          <li>Strong password policies and multi-factor authentication</li>
          <li>Regular data backups and recovery planning</li>
          <li>Encryption for sensitive data</li>
        </ul>

        <div class="info-box">
          <h3>Security Tip</h3>
          <p>
            Regularly update all software and systems to ensure you have the latest security patches and protections.
          </p>
        </div>

        <h2>Common Security Threats</h2>
        <p>
          Understanding potential threats is the first step in protecting your business. Here are some common security challenges:
        </p>

        <ol>
          <li>Phishing attacks</li>
          <li>Ransomware</li>
          <li>Social engineering</li>
          <li>Insider threats</li>
        </ol>
      `
    },
    3: {
      title: "The Impact of AI on Data Analysis",
      date: "March 8, 2024",
      author: "redMug.co",
      readTime: "6 min read",
      category: "Artificial Intelligence",
      image: "/images/blog/ai-analysis.svg",
      content: `
        <p class="lead">
          Artificial Intelligence is revolutionizing how businesses analyze and interpret data, leading to more accurate insights and faster decision-making.
        </p>

        <h2>AI-Powered Analytics</h2>
        <p>
          AI technologies are transforming traditional data analysis methods by introducing advanced capabilities such as:
        </p>

        <ul>
          <li>Automated pattern recognition</li>
          <li>Predictive analytics</li>
          <li>Natural language processing</li>
          <li>Machine learning algorithms</li>
        </ul>

        <blockquote>
          "AI isn't replacing human analysts; it's empowering them to focus on higher-value tasks and strategic thinking."
        </blockquote>

        <h2>Real-World Applications</h2>
        <p>
          Here are some ways businesses are leveraging AI for data analysis:
        </p>

        <ol>
          <li>Customer behavior prediction</li>
          <li>Market trend analysis</li>
          <li>Risk assessment</li>
          <li>Process optimization</li>
        </ol>

        <div class="info-box">
          <h3>Implementation Tip</h3>
          <p>
            Start with a specific use case where AI can provide immediate value, then expand based on success and learnings.
          </p>
        </div>
      `
    },
    4: {
      title: "Building RAG Pipelines with Local LLMs: A Practical Guide",
      date: "March 18, 2024",
      author: "redMug.co",
      readTime: "8 min read",
      category: "Machine Learning",
      image: "/images/blog/llm-rag.svg",
      content: `
        <p class="lead">
          Local Large Language Models (LLMs) combined with Retrieval-Augmented Generation (RAG) are revolutionizing how organizations handle private data processing and AI implementation.
        </p>

        <h2>Understanding Local LLMs and RAG</h2>
        <p>
          Running LLMs locally offers significant advantages in terms of data privacy, latency, and cost control. When combined with RAG pipelines, 
          organizations can create powerful systems that leverage both their proprietary data and the capabilities of AI models.
        </p>

        <h2>Key Components of a RAG Pipeline</h2>
        <ul>
          <li>Document processing and chunking</li>
          <li>Vector embeddings generation</li>
          <li>Vector store implementation</li>
          <li>Context retrieval system</li>
          <li>Local LLM integration</li>
        </ul>

        <div class="info-box">
          <h3>Performance Tip</h3>
          <p>
            Optimize your chunk size based on your specific use case. Smaller chunks work better for precise information retrieval, 
            while larger chunks maintain more context.
          </p>
        </div>

        <h2>Popular Local LLM Options</h2>
        <p>
          Several efficient models are available for local deployment:
        </p>

        <ol>
          <li>Llama 2</li>
          <li>Mistral</li>
          <li>GPT4All</li>
          <li>Falcon</li>
        </ol>

        <blockquote>
          "The future of enterprise AI lies in the ability to leverage powerful language models while maintaining complete control over sensitive data."
        </blockquote>

        <h2>Implementation Steps</h2>
        <p>
          Building a RAG pipeline involves several key steps:
        </p>

        <ol>
          <li>Prepare and preprocess your document collection</li>
          <li>Generate and store embeddings using models like BERT or Ada</li>
          <li>Set up a vector database (e.g., Chroma, FAISS, or Milvus)</li>
          <li>Implement similarity search for relevant context retrieval</li>
          <li>Configure your local LLM for generation</li>
          <li>Create a prompt template that effectively uses retrieved context</li>
        </ol>

        <div class="info-box">
          <h3>Resource Management</h3>
          <p>
            Consider using quantized models and efficient embedding techniques to reduce memory usage while maintaining acceptable performance levels.
          </p>
        </div>
      `
    },
    5: {
      title: "Optimizing Data Pipelines for Scale",
      date: "March 20, 2024",
      author: "redMug.co",
      readTime: "7 min read",
      category: "Data Engineering",
      image: "/images/blog/bi-dashboard.svg",
      content: `
        <p class="lead">
          As organizations handle increasingly large volumes of data, optimizing data pipelines for scale becomes crucial for maintaining performance and reliability.
        </p>

        <h2>Understanding Pipeline Scalability</h2>
        <p>
          Modern data pipelines must handle growing data volumes while maintaining processing efficiency and data quality. 
          This requires careful consideration of architecture, processing patterns, and monitoring strategies.
        </p>

        <h2>Key Optimization Strategies</h2>
        <ul>
          <li>Parallel processing implementation</li>
          <li>Efficient data partitioning</li>
          <li>Caching and materialization</li>
          <li>Resource allocation optimization</li>
          <li>Error handling and recovery</li>
        </ul>

        <blockquote>
          "The success of a data pipeline lies not just in its ability to process data, but in its ability to scale seamlessly with growing demands."
        </blockquote>

        <h2>Batch vs. Streaming Processing</h2>
        <p>
          Choosing the right processing pattern is crucial for pipeline optimization:
        </p>

        <ol>
          <li>Evaluate data freshness requirements</li>
          <li>Consider resource utilization patterns</li>
          <li>Analyze data volume and velocity</li>
          <li>Plan for future scaling needs</li>
        </ol>

        <div class="info-box">
          <h3>Performance Tip</h3>
          <p>
            Implement incremental processing whenever possible to reduce resource usage and processing time for large datasets.
          </p>
        </div>
      `
    },
    6: {
      title: "Machine Learning Model Monitoring Best Practices",
      date: "March 22, 2024",
      author: "redMug.co",
      readTime: "6 min read",
      category: "Machine Learning",
      image: "/images/blog/ai-analysis.svg",
      content: `
        <p class="lead">
          Effective model monitoring is essential for maintaining the performance and reliability of machine learning systems in production environments.
        </p>

        <h2>Key Monitoring Metrics</h2>
        <p>
          Comprehensive model monitoring requires tracking various metrics to ensure optimal performance and detect potential issues early.
        </p>

        <ul>
          <li>Model accuracy and performance metrics</li>
          <li>Data drift detection</li>
          <li>Prediction latency</li>
          <li>Resource utilization</li>
          <li>Feature importance stability</li>
        </ul>

        <div class="info-box">
          <h3>Monitoring Tip</h3>
          <p>
            Set up automated alerts for significant deviations in model performance metrics to enable quick response to potential issues.
          </p>
        </div>

        <h2>Implementation Strategy</h2>
        <p>
          Follow these steps to establish effective model monitoring:
        </p>

        <ol>
          <li>Define key performance indicators (KPIs)</li>
          <li>Set up monitoring infrastructure</li>
          <li>Implement automated testing</li>
          <li>Establish retraining triggers</li>
        </ol>

        <blockquote>
          "Proactive model monitoring is not just about maintaining performance—it's about building trust in your ML systems."
        </blockquote>
      `
    },
    7: {
      title: "Data Governance in the Age of AI",
      date: "March 25, 2024",
      author: "redMug.co",
      readTime: "8 min read",
      category: "Data Governance",
      image: "/images/blog/security.svg",
      content: `
        <p class="lead">
          As AI systems become more prevalent, establishing robust data governance frameworks is crucial for ensuring responsible and effective use of data.
        </p>

        <h2>Core Governance Principles</h2>
        <p>
          Effective data governance in the AI era requires a comprehensive approach that addresses both traditional and AI-specific challenges.
        </p>

        <ul>
          <li>Data quality and consistency standards</li>
          <li>Privacy and security protocols</li>
          <li>Ethical AI guidelines</li>
          <li>Compliance frameworks</li>
          <li>Data lineage tracking</li>
        </ul>

        <div class="info-box">
          <h3>Governance Tip</h3>
          <p>
            Implement automated data quality checks and validation processes to maintain high standards across your data pipeline.
          </p>
        </div>

        <h2>Implementation Framework</h2>
        <p>
          Follow these steps to establish effective data governance:
        </p>

        <ol>
          <li>Define governance policies and standards</li>
          <li>Establish roles and responsibilities</li>
          <li>Implement monitoring and compliance tools</li>
          <li>Create documentation and training programs</li>
        </ol>

        <blockquote>
          "Strong data governance is the foundation of responsible AI implementation and sustainable data practices."
        </blockquote>

        <div class="info-box">
          <h3>Best Practice</h3>
          <p>
            Regular audits and reviews of governance practices ensure continued effectiveness and alignment with evolving regulations.
          </p>
        </div>
      `
    }
  };

  const post = blogPosts[id];

  if (!post) {
    return (
      <div className="page-container">
        <div className="max-w-3xl mx-auto">
          <div className="content-section text-center">
            <h1 className="heading-1">Post Not Found</h1>
            <p className="mb-6">The blog post you're looking for doesn't exist.</p>
            <Link to="/blog" className="btn-primary">
              Return to Blog
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="max-w-3xl mx-auto">
        {/* Back to Blog Link */}
        <Link 
          to="/blog" 
          className="inline-flex items-center text-[var(--primary-color)] hover:text-[var(--accent-color)] mb-6 transition-colors"
        >
          <svg 
            className="w-4 h-4 mr-2" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M15 19l-7-7 7-7" 
            />
          </svg>
          Back to Blog
        </Link>

        {/* Article Container */}
        <article className="content-section">
          {/* Article Header */}
          <header className="mb-8">
            <div className="flex items-center mb-4">
              <span className="inline-block bg-[var(--accent-color)] text-white text-sm px-3 py-1 rounded-full">
                {post.category}
              </span>
            </div>
            <h1 className="text-4xl font-bold text-[var(--primary-color)] mb-4">
              {post.title}
            </h1>
            <div className="flex items-center text-gray-600 text-sm">
              <span>{post.author}</span>
              <span className="mx-2">•</span>
              <span>{post.date}</span>
              <span className="mx-2">•</span>
              <span>{post.readTime}</span>
            </div>
          </header>

          {/* Featured Image */}
          <div className="mb-8">
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>

          {/* Article Content */}
          <div 
            className="prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: post.content }} 
          />

          {/* Author Bio */}
          <div className="mt-12 p-6 bg-[var(--neutral-light)] rounded-lg">
            <div className="flex items-center">
              <img
                src="/images/logo/redmug-icon.svg"
                alt="redMug.co"
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <h3 className="font-bold text-lg text-[var(--primary-color)]">
                  {post.author}
                </h3>
                <p className="text-gray-600">
                  Data Analytics Solutions
                </p>
              </div>
            </div>
          </div>

          {/* Share Buttons */}
          <div className="mt-8 flex items-center justify-center space-x-4">
            <button className="btn-secondary">
              Share on Twitter
            </button>
            <button className="btn-secondary">
              Share on LinkedIn
            </button>
          </div>
        </article>

        {/* Newsletter Sign-up */}
        <div className="mt-12 content-section bg-[var(--neutral-light)] text-center">
          <h3 className="heading-2">Stay Updated</h3>
          <p className="mb-6">
            Get the latest insights delivered to your inbox.
          </p>
          <form className="flex gap-4 max-w-md mx-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className="form-input flex-1"
            />
            <button type="submit" className="btn-primary whitespace-nowrap">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
